import logo from './logo.svg';
import './App.css';
import Feed from './Feed';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
function App() {
  return (

    <BrowserRouter>
<Routes>
          <Route path="/:brand" element={<Feed />} />
</Routes>

    </BrowserRouter>

  );
}
export default App;
