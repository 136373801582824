import React, { Component } from 'react';

class Feed extends Component {
	constructor(props){
		super(props);
	}

	componentDidMount(){
		var pathArray = window.location.pathname.split('/');
		var client = pathArray[1];
		//window.location.href = "https://www.shoptik.app/" + client;

    var l = "https://www.shoptik.app/" + client;

    var j = document.getElementById("iframe");
    j.src = l;


//
// var window = j.contentWindow;
// var userAgent = "mobile";

// if (window.navigator.userAgent != userAgent) {
//   var userAgentProp = {
//     get: function() {
//       return userAgent;
//     }
//   };
//   try {
//     Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
//   } catch (e) {
//     window.navigator = Object.create(navigator, {
//       userAgent: userAgentProp
//     });
//   }
// }


	}


	render() {
			return(
				<iframe src="" width="100%" height="100%" frameborder="0" id="iframe"></iframe>
			)
		}
}

export default Feed
